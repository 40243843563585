













































































































































































#date-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
